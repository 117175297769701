import React, { Component } from "react";
import "../../style/common/footerBar.scss";
import Facebook from "../../icon/Facebook";
import Instagram from "../../icon/Instagram";
import Pinterest from "../../icon/Pinterest";

import TikTok from "../../icon/TikTok";
import footImg from "../../image/footer.png";
import facebook from "../../image/facebook.svg";
import twitter from "../../image/twitter.svg";
import instagram from "../../image/instagram.svg";
import pinterest from "../../image/pinterest.svg";
import youtube from "../../image/youtube.svg";
import footer from "../../image/footer.svg";
import logo from "../../image/logo.png";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "about":
        history.push("/About");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "shipping":
        history.push("/Shipping");
        break;
      case "terms":
        history.push("/Terms");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="footerBar">
         <p>Webデザインギャラリー</p>
         <h2>Stock</h2>
         <p>WebデザインギャラリーサイトStockでは、</p>
         <p>Webデザインの現場で参考になる日本の優れたWebデザインを集めております。</p>
         <p>あなたのインスピレーションを刺激してみてください。</p>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterBar;
