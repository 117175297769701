import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import React, { Component } from "react";
import "../style/contact.scss";
import sec6_people from "../image/sec6-people.jpg";
import contact from "../image/contact.png";
import contact1 from "../image/contact1.jpg";
import contact2 from "../image/contact2.png";
import contact3 from "../image/contact3.jpg";
import contact4 from "../image/contact4.png";

class ContacUs extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="contact-page">
          <h1>お問い合わせ</h1>
          <p>
            Stock内で紹介させていただいているサイトの掲載取下げや修正のご相談、お問い合わせについては以下フォームよりご連絡ください。
            また、掲載したいWebサイトのご報告もお待ちしております。自薦他薦問いません。
            ただ、当サイトの方針に合わないものについては掲載できない場合がございます。予めご了承ください。
          </p>
          <div className="input-row">
            <div className="title">
              お問い合わせ件 <span>必須</span>
            </div>
            <div className="right">
              <div className="row">
                <input type="radio" />
                <p>Stockに関するお問い合わせ</p>
              </div>
              <div className="row">
                <input type="radio" />
                <p>掲載取下げや修正のご相談</p>
              </div>
              <div className="row">
                <input type="radio" />
                <p>掲載のご相談</p>
              </div>
              <div className="row">
                <input type="radio" />
                <p>その他のお問い合わせ</p>
              </div>
            </div>
          </div>
          <div className="input-row">
            <div className="title">お名前 <span>必須</span></div>
            <input type="text" />
          </div>
          <div className="input-row">
            <div className="title">メールアドレス <span>必須</span></div>
            <input type="text" />
          </div>
          <div className="input-row">
            <div className="title">URL</div>
            <input type="text" />
          </div>
          <div className="input-row">
            <div className="title">お問い合わせ内容 <span>必須</span></div>
            <textarea name="" id=""></textarea>
          </div>
          <div className="btn">入力内容を送信する</div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ContacUs;
