import React, { Component } from "react";
import "../../style/common/headerBar.scss";
import ShoppingCart from "../../icon/ShoppingCart";
import logo from "../../image/logo.svg";

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartList: [],
      quantity: 0,
    };
    this.routePage = this.routePage.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem("cart")) {
      let cartList = JSON.parse(localStorage.getItem("cart"));
      let quantity = 0;
      cartList.map((item) => {
        quantity += item.quantity;
      });
      this.setState({ cartList, quantity });
    }
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "cart":
        history.push("/Cart");
        break;
      default:
        break;
    }
  }

  render() {
    const { quantity } = this.state;
    return (
      <React.Fragment>
        <div className="header">
          <div className="left">
            <div className="logo">
              <img src={logo} alt="" onClick={() => this.routePage("home")} />
              <p>日本の優れたWebデザインを集めてみた</p>
            </div>

            <div className="tab">
              <div className="li" onClick={() => this.routePage("about")}>
                About
              </div>
              <div className="li" onClick={() => this.routePage("contact")}>
                Contact
              </div>
            </div>
          </div>
          {/* <div className="shop" onClick={() => this.routePage("cart")}>
            <ShoppingCart size={30} color="#fff" />
            <div className="num">{quantity}</div>
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
