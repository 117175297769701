import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../style/home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { productList } from "../apis/GetProduct";
import ShoppingCart from "../icon/ShoppingCart";
import AddIcon from "../icon/AddIcon";
import LikeIcon from "../icon/LikeIcon";
import ShareIcon from "../icon/ShareIcon";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.routePage = this.routePage.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="home-page">
          <div className="sec1-row">
            {productList.map((item, index) => {
              return (
                <div
                  className="col"
                  key={index}
                  onClick={() => this.routeProductDetail(item.id)}
                >
                  <div className="like">
                    <LikeIcon size={40} />
                  </div>
                  <div className="img-box">
                    <img src={item.img} alt="" />
                  </div>
                  <div className="txt">
                    <p>{item.title}</p>
                    <div className="share">
                      <ShareIcon size={14} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
