import React from "react";
const LikeIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#fff",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="40461"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 1024c-282.482759 0-512-229.517241-512-512s229.517241-512 512-512 512 229.517241 512 512-229.517241 512-512 512z m0-953.37931c-243.641379 0-441.37931 197.737931-441.37931 441.37931s197.737931 441.37931 441.37931 441.37931 441.37931-197.737931 441.37931-441.37931-197.737931-441.37931-441.37931-441.37931z"
      p-id="40462"
      fill={color}
    ></path>
    <path
      d="M522.593103 706.206897s-293.075862-240.110345-144.772413-317.793104c86.510345-45.903448 143.006897 12.358621 143.006896 12.358621s56.496552-58.262069 143.006897-12.358621c150.068966 79.448276-141.241379 317.793103-141.24138 317.793104"
      p-id="40463"
      fill={color}
    ></path>
  </svg>
);

export default LikeIcon;
